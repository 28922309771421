import '../LearnBlockList2/styles.scss';
import React, {memo, useEffect, useMemo} from 'react';
import {WITH_API_BASE} from "../../configs/appConfigs";
import {LearnGridComponent} from "../../types/ApiComponent";
import {Crown2, arrRight2, apple} from "../../images";
import {Link, navigate} from "gatsby";

type Props = {
    data: LearnGridComponent,
    pagePath: string,
    fullLink: string,
}

const LearnBlockList3 = ({data, pagePath, fullLink}: Props) => {
    const renderBlocks = useMemo(() => {
        return data.Blocks.map((block, index) => (
            <div key={`item-${index}`} className="learnBlock learnBlock2">
                <div className="learnBlockImage learnBlock2Image">
                    <img src={WITH_API_BASE(block?.ImageLink || '')} width={204} alt=""/>
                </div>
                <div className="learnBlockTitle learnBlock2Title">
                    {block.Title}
                </div>
                <div className="learnBlockDesc learnBlock2Desc">
                    {block.Description}
                </div>
            </div>
        ))
    }, [data])

    return (
        <>
            <div id={'skills'} className="container">
                <div className="homeStatTitle">Get all the resources for success <br /> with the AEER platform</div>
                <div className="homeStatDesc">Save 2 years of training and get the most in-demand skills now. Learn and use them in your daily life. Enjoy your growth and changes.</div>
                <div className="learnBlockGrid">
                    {renderBlocks}
                </div>
            </div>
            <div className="buttonBlock2">

                <Link to={fullLink} className="btnv2 btn-primary noEventsInside" id={`pageCorpTry${pagePath}BtnThird`}>
                    <img src={Crown2} className="btn-img" width={30} alt=""/>
                    <span>Try AEER</span>
                    <img src={arrRight2} className="btn-img" width={30} alt=""/>
                </Link>

            </div>
        </>
    )
}

export default memo(LearnBlockList3);
